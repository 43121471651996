body {
    margin: 0;
    padding: 0;
    background-color: #121212;
    color: #e0e0e0;
    font-family: 'Roboto Mono', monospace;
}

/* Add these styles at the beginning of the file, after the body styles */

.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
    height: 60px; /* Set an explicit height for the header */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.header-left {
    display: flex;
    align-items: center;
}

.app-title {
    font-size: 2rem;
    margin-right: 1rem;
    text-decoration: none;
    color: #54a0ff; /* Changed to skyblue */
    font-weight: 700; /* Make the title bold */
}

.algorithm-name {
    font-size: 1.5rem;
    margin: 0;
    color: #5ed5a8; /* Changed to green */
    font-weight: 400; /* Keep this regular weight */
}

.login-button {
    padding: 8px 16px;
    background-color: #2c2c2c;
    color: #ff9ff3; /* Changed to pink */
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 14px;
}

.login-button:hover {
    background-color: #3c3c3c;
    color: #ffffff;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 2rem;
    padding-top: 120px; /* Adjust this value based on your header and controls panel height */
}

.red {
    fill: #ff6b6b;
}

.yellow {
    fill: #feca57;
}

.green {
    fill: #5ed5a8;
}

.orange {
    fill: #ff9ff3;
}

.skyblue {
    fill: #54a0ff;
}

rect {
    transition: all 300ms ease-in-out;
    rx: 5px;
    ry: 5px
}

.control-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #1e1e1e;
    border-top: 1px solid #333;
    box-sizing: border-box;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.speed-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    margin-right: 10px;
}

.speed-range {
    width: 100%;
    margin: 0;
}

.speed-control span {
    font-size: 0.8rem;
    margin-top: 5px;
}

.state-range {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
}

.array-creator {
    display: flex;
    justify-content: space-between;
    height: 1.5rem;
    margin-bottom: 1rem;
}

.random-array-creator, .input-array-creator {
    display: flex;
}

.algorithm-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 1200px;
    min-height: 100vh;
    text-align: center;
    padding: 2rem;
    margin: 0 auto;
    box-sizing: border-box;
}

.algorithm-grid h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #e0e0e0;
}

.algorithm-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    width: 100%;
}

.algorithm-card {
    background-color: #1e1e1e;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    width: calc(25% - 2rem);
    min-width: 200px;
    max-width: 300px;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .algorithm-card {
        width: calc(33.33% - 2rem);
    }
}

@media (max-width: 900px) {
    .algorithm-card {
        width: calc(50% - 2rem);
    }
}

@media (max-width: 600px) {
    .algorithm-card {
        width: 100%;
    }
}

.algorithm-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
}

.algorithm-card.inactive:hover {
    transform: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    cursor: not-allowed;
}

.algorithm-card h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #e0e0e0;
}

.algorithm-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.algorithm-card-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.algorithm-card.inactive .algorithm-card-link {
    pointer-events: none;
}

.algorithm-card-link:hover {
    text-decoration: none;
}

button {
    padding: 8px 16px;
    background-color: #2c2c2c;
    color: #feca57; /* Changed to yellow */
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 14px;
}

button:hover {
    background-color: #3c3c3c;
    color: #ffffff;
}

input[type="text"], textarea {
    background-color: #333;
    color: #54a0ff; /* Changed to skyblue */
    border: 1px solid #444;
    padding: 0.5rem;
    border-radius: 5px;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #333;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

input[type="range"]:hover {
    opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e0e0e0;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e0e0e0;
    cursor: pointer;
}

/* Add these styles at the end of the file */

header {
    position: sticky;
    top: 0;
    z-index: 1000;
}

/* Add or update these styles */

.controls button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 2px;
    transition: background-color 0.3s ease;
}

.controls button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.controls button:focus {
    outline: none;
}

.speed-range, .state-range {
    margin: 0 10px;
}

/* Update these styles in your App.css file */

.control-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #1e1e1e;
    border-top: 1px solid #333;
    box-sizing: border-box;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.speed-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    margin-right: 10px;
}

.state-range {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
}

/* Add this new style for the buttons container */
.control-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Update button styles */
.controls button {
    padding: 5px;
    margin: 0 2px;
}

/* Add these new styles at the end of the file */

.sorted {
    fill: #ff9ff3;
}

.current-pointer {
    fill: #ff6b6b; /* Both pointers are now red */
}

.left-subarray {
    fill: #feca57;
}

.right-subarray {
    fill: #5ed5a8;
}

.default {
    fill: #54a0ff;
}

.inactive {
    fill: #54a0ff;
    opacity: 0.3;
}

/* Update the transition for smoother animations */
rect {
    transition: all 300ms ease-in-out;
    rx: 5px;
    ry: 5px;
}

/* Add these new styles at the end of the file */

.pivot {
    fill: #ff6b6b; /* Red */
}

.left-pointer {
    fill: #5ed5a8; /* Green */
}

.right-pointer {
    fill: #feca57; /* Yellow */
}

.swapped {
    fill: #a29bfe; /* Purple */
}

.left-partition {
    fill: #81ecec; /* Cyan */
}

.right-partition {
    fill: #81ecec; /* Cyan */
}

.unsorted {
    fill: #54a0ff; /* Blue */
}

.sorted {
    fill: #ff9ff3; /* Pink */
}

.inactive {
    fill: #dfe6e9;
    opacity: 0.3;
}

/* Update or add these styles */
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
}

.content-container {
    padding-top: 120px; /* Increase this value to account for both header and controls panel */
}

button, input, textarea {
    font-family: 'Roboto Mono', monospace;
}
