.app-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    width: 80vw;
    margin: auto;
    padding-top: 1rem;
}

.array-creator {
    display: flex;
    justify-content: space-between;
    height: 1.5rem;
    margin-bottom: 1rem;
}

.random-array-creator, .input-array-creator {
    display: flex;
}

.algorithm-explorer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-top: 120px; /* Adjust this value based on your header and controls panel height */
}

.algorithm-explorer h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.controls-panel-wrapper {
    position: fixed;
    top: 60px; /* This should be the exact height of your header */
    left: 0;
    right: 0;
    z-index: 999; /* Set this to be lower than the header's z-index */
    background-color: #121212;
    width: 100%;
}

.controls-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 15px;
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
}

.input-array-creator,
.random-array-creator {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-array-creator textarea,
.random-array-creator input {
    padding: 8px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #2c2c2c;
    color: #54a0ff; /* Changed to skyblue */
    font-family: 'Roboto Mono', monospace;
}

/* Update button styles */
button {
    padding: 8px 16px;
    background-color: #2c2c2c;
    color: #feca57; /* Changed to yellow */
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 14px;
    font-family: 'Roboto Mono', monospace;
}

button:hover {
    background-color: #3c3c3c;
    color: #ffffff;
}

.visualization-container {
    width: 100%;
    margin-top: 20px;
}

.random-array-creator select {
    width: 180px; /* Increased width */
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #2c2c2c;
    color: #54a0ff; /* Changed to skyblue to match other inputs */
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='%2354a0ff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
    font-family: 'Roboto Mono', monospace;
}

.random-array-creator select:focus {
    outline: none;
    border-color: #54a0ff;
    box-shadow: 0 0 0 2px rgba(84, 160, 255, 0.25);
}

.random-array-creator select:hover {
    border-color: #54a0ff;
}

.random-array-creator select option {
    background-color: #2c2c2c;
    color: #54a0ff;
}

.random-array-creator {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: center; /* Center the items if they wrap */
}

.random-array-creator input[type='number'] {
    width: 60px; /* Adjust as needed */
}
