.progress-range {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-appearance: none;
    background: #333;
    outline: none;
    border-radius: 15px;
}

.progress-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #e0e0e0;
    cursor: pointer;
    border-radius: 50%;
}

.progress-range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #e0e0e0;
    cursor: pointer;
    border-radius: 50%;
}

.progress-range::-webkit-slider-runnable-track {
    height: 10px;
    border-radius: 15px;
}

.progress-range::-moz-range-track {
    height: 10px;
    border-radius: 15px;
}

/* This creates the filled part of the range input */
.progress-range {
    background: linear-gradient(to right, #ff69b4 0%, #ff69b4 50%, #333 50%, #333 100%);
}
