.control-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #1e1e1e;
    border-top: 1px solid #333;
    box-sizing: border-box;
}

.controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.speed-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    margin-right: 10px;
}

.speed-range {
    width: 100%;
    margin: 0;
}

.speed-control span {
    font-size: 0.8rem;
    margin-top: 5px;
}

.control-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.controls button {
    padding: 5px;
    margin: 0 2px;
}
